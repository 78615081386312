import * as React from "react";
import { styled } from "@mui/material/styles";
import { Alert, Snackbar, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import { questionsLegalForm } from "../../../utils/legalFormArray";
import "./style.css";
import { formatCPFOrCNPJ, removeSpecialCharacters } from "../../../utils/formattedTexts";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import { useErrorContext } from "../../../context/errorContext";
import { useTranslation } from "react-i18next";

require("../../tokenRenewal");

const TypographyStyled = styled(Typography)({
  marginTop: "20px",
  textAlign: "left",
  marginBottom: "5px",
});

const LegalForm = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { button, token } = props;
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [answers, setAnswers] = useState({});

  const navigate = useNavigate();
  const { errorMessages, setErrorMessages, openError, setOpenError } =
    useErrorContext();

  useImperativeHandle(
    ref,
    () => ({
      async submitForm() {
        const formValues = getValues();
        let messages = [];
        let openStates = [];

        if (button !== undefined && button !== "") {
          if (button === "finalize") {
            for (const value in formValues) {
              if (
                (formValues[value] === null || formValues[value] === "") &&
                value !== "statusLegalForm" &&
                value !== "dateLegalForm" &&
                value !== "cpfOrCnpjLegal2" &&
                value !== "legal2" &&
                value !== "occupationLegal2" &&
                value !== "emailLegal2" &&
                value !== "analysis"
              ) {
                messages.push(
                  `Campo obrigatório vazio na sessão "Informações contratuais": ${t(
                    value
                  )}`
                );
                openStates.push(true);
              }
            }

            if (!formValues.pix || formValues.pix.length < 11) {
              messages.push(
                "* Valor inválido para o campo 'Pix'. Quantidade mínima de caracteres: 11."
              );
              openStates.push(true);
            }

            if (
              formValues.cpfOrCnpjLegal1.length !== 14 &&
              formValues.cpfOrCnpjLegal1.length !== 18
            ) {
              messages.push(
                "* Digite um CPF ou CNPJ válido para o Representante legal 1."
              );
              openStates.push(true);
            }

            if (
              formValues.cpfOrCnpjLegal2.length > 0 &&
              formValues.cpfOrCnpjLegal2.length !== 14 &&
              formValues.cpfOrCnpjLegal2.length !== 18
            ) {
              messages.push(
                "* Digite um CPF ou CNPJ válido para o Representante legal 2."
              );
              openStates.push(true);
            }

            if (
              formValues.cpfOrCnpjAccountHolder.length !== 14 &&
              formValues.cpfOrCnpjAccountHolder.length !== 18
            ) {
              messages.push(
                "* Digite um CPF ou CNPJ válido em 'Dados Bancários."
              );
              openStates.push(true);
            }

            if (
              !formValues.emailLegal1.includes("@") ||
              !formValues.emailLegal1.includes(".")
            ) {
              messages.push(
                "* Digite um e-mail válido para o Representante legal 1."
              );
              openStates.push(true);
            }

            if (
              formValues.emailLegal2.length > 0 &&
              (!formValues.emailLegal2.includes("@") ||
                !formValues.emailLegal2.includes("."))
            ) {
              messages.push(
                "* Digite um e-mail válido para o Representante legal 2."
              );
              openStates.push(true);
            }

            if (messages.length > 0) {
              setErrorMessages(messages);
              setOpenError(openStates);
              return false;
            }

            try {
              await handleSubmit(onSubmit)();
              return true;
            } catch (error) {
              console.error("Error during form submission:", error);
              return false;
            }
          } else if (button === "save") {
            await handleSubmit(onSubmit)();
            return true;
          }
        }
        return true;
      },
    }),
    [button]
  );

  const handleClose = (index) => {
    setOpenError((prev) =>
      prev.map((openState, i) => (i === index ? false : openState))
    );
  };

  const getAnalysis = React.useCallback(async () => {
    setLoading(true);
    try {
      const email = localStorage.getItem("email");
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/analysisbyemail/" + email,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setAnalysis(response.data);
    } catch (error) {
      console.error("Error fetching analysis:", error);
    } finally {
      setLoading(false);
    }
  }, [token, navigate]);

  const getAnswers = React.useCallback(async () => {
    if (!analysis) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/legal/" + analysis?.id,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      setAnswers(response.data.legalForm || {});
    } catch (error) {
      console.error("Error fetching answers:", error);
    } finally {
      setLoading(false);
    }
  }, [analysis, token]);

  useEffect(() => {
    getAnalysis();
  }, [getAnalysis]);

  useEffect(() => {
    if (analysis) {
      getAnswers();
    }
  }, [analysis, getAnswers]);

  useEffect(() => {
    if (Object.keys(answers).length > 0) {
      for (const [key, value] of Object.entries(answers)) {
        setValue(key, value);
      }
    }
  }, [answers, setValue]);

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      await axios.post(
        process.env.REACT_APP_API_URL + "/legalform/" + analysis?.id,
        {
          branch: formData.branch,
          bank: formData.bank,
          account: formData.account,
          accountHolder: formData.accountHolder,
          accountHolderLegalOrNaturalPerson:
            formData.accountHolderLegalOrNaturalPerson,
          cpfOrCnpjAccountHolder: formData.cpfOrCnpjAccountHolder,
          legal1: formData.legal1,
          emailLegal1: formData.emailLegal1,
          cpfOrCnpjLegal1: formData.cpfOrCnpjLegal1,
          occupationLegal1: formData.occupationLegal1,
          legal2: formData.legal2,
          emailLegal2: formData.emailLegal2,
          cpfOrCnpjLegal2: formData.cpfOrCnpjLegal2,
          occupationLegal2: formData.occupationLegal2,
          pix: formData.pix,
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCPFOrCNPJChange = (event) => {
    const { id, value } = event.target;
    setValue(id, formatCPFOrCNPJ(value));
  };

  if (loading) {
    return (
      <Container>
        <CenteredCircularProgress />
      </Container>
    );
  }

  if (!analysis) {
    return (
      <Container>
        <CenteredCircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <h3 style={{ color: "#e5007e" }}>Informações Contratuais</h3>
        {questionsLegalForm.map((question, index) => (
          <React.Fragment key={index}>
            <Typography sx={{ fontWeight: "bold", marginBottom: "10px" }}>
              {question.title}
            </Typography>
            <Grid style={{ marginBottom: "5px" }} container spacing={2}>
              {question.fields.map((field, idx) => (
                <Grid item xs={12} sm={6} key={idx}>
                  <TypographyStyled>{field.label}</TypographyStyled>
                  <FormControl fullWidth size="small">
                    {field.type === "select" ? (
                      <TextField
                        size="small"
                        id={field.id}
                        variant="outlined"
                        placeholder={field.placeholder ? field.placeholder : ""}
                        select
                        {...register(field.id)}
                        required
                        defaultValue=""
                        SelectProps={{
                          native: true,
                        }}
                      >
                        <option value="" disabled>
                          Selecione
                        </option>
                        {field.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        placeholder={field.placeholder ? field.placeholder : ""}
                        size="small"
                        id={field.id}
                        variant="outlined"
                        {...register(field.id)}
                        type={field.type}
                        required
                        onChange={(event) => {
                          const { id, value } = event.target;
                          if (field.label === "Agência*" || field.label === "Conta*") {
                            setValue(id, removeSpecialCharacters(value));
                          } else if (field.label.includes("CPF/CNPJ")) {
                            setValue(id, formatCPFOrCNPJ(value));
                          } else {
                            setValue(id, value);
                          }
                        }}
                      />
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </Box>

      {errorMessages.map((message, index) => (
        <Snackbar
          key={index}
          open={openError[index]}
          autoHideDuration={6000}
          onClose={() => handleClose(index)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            marginBottom: `${index * 60}px`,
          }}
        >
          <Alert onClose={() => handleClose(index)} severity="error">
            {message}
          </Alert>
        </Snackbar>
      ))}
    </Container>
  );
});

export default React.memo(LegalForm);
