import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Header from "../../../components/Header";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";
import { ButtonBase, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import ForecastPreview from "./ForecastPreview";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import "./style.css";
import CardInit from "../../../components/CardInit/CardInit";
import { MdDownloading } from "react-icons/md";
import Footer from "../../../components/Footer";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";

require("../../tokenRenewal");

const fileTypes = ["XLSX"];

function getMccsValue(data) {
  const mccs = [];
  for (let i = 18; i < data.length && !!data[i].__EMPTY; i += 3) {
    mccs.push({
      mcc: data[i].__EMPTY,
      mccBillingProjectionByProduct: data[i].__EMPTY_1 * 100,
      debit: data[i].__EMPTY_3,
      mccTotalTpv: data[i].__EMPTY_4,
      averageTicketDebit: data[i].__EMPTY_5,
      credit: data[i + 1].__EMPTY_3,
      averageTicketCredit: data[i + 1].__EMPTY_5,
      installment: data[i + 2].__EMPTY_3,
      averageTicketInstallments: data[i + 2].__EMPTY_5,
    });
  }

  return mccs;
}

function getInformation(data) {
  return {
    billingMonth1: get(data, "[1].__EMPTY_1", 0),
    billingMonth2: get(data, "[2].__EMPTY_1", 0),
    billingMonth3: get(data, "[3].__EMPTY_1", 0),
    billingMonth4: get(data, "[4].__EMPTY_1", 0),
    billingMonth5: get(data, "[5].__EMPTY_1", 0),
    billingMonth6: get(data, "[6].__EMPTY_1", 0),
    billingMonth7: get(data, "[7].__EMPTY_1", 0),
    billingMonth8: get(data, "[8].__EMPTY_1", 0),
    billingMonth9: get(data, "[9].__EMPTY_1", 0),
    billingMonth10: get(data, "[10].__EMPTY_1", 0),
    billingMonth11: get(data, "[11].__EMPTY_1", 0),
    billingMonth12: get(data, "[12].__EMPTY_1", 0),
    billingMonthTotal: get(data, "[13].__EMPTY_1", 0),
    performanceByDebit: Math.round(get(data, "[1].__EMPTY_4", 0) * 100).toFixed(
      2
    ),
    performanceByCredit: Math.round(
      get(data, "[2].__EMPTY_4", 0) * 100
    ).toFixed(2),
    performanceByInstallment: Math.round(
      get(data, "[3].__EMPTY_4", 0) * 100
    ).toFixed(2),
    performanceWithCard: Math.round(
      get(data, "[6].__EMPTY_4", 0) * 100
    ).toFixed(2),
    performanceWithoutCard: Math.round(
      get(data, "[7].__EMPTY_4", 0) * 100
    ).toFixed(2),
    prepayment: Math.round(get(data, "[9].__EMPTY_4", 0) * 100).toFixed(2),
    averageSettlementTerm: get(data, "[10].__EMPTY_4", 0),
  };
}

function Forecast(props) {
  const [data, setData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mccs, setMccs] = useState([]);
  const analysis = JSON.parse(localStorage.getItem("analysis"));
  const navigate = useNavigate();
  const forecastCompleted = analysis ? analysis.statusForecastCompleted : null;

  const readExcel = (file) => {
    setIsUploading(true);
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[1];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { range: 1 });
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setPreviewOpen(true);
      setIsUploading(false);
      setData(d);
      setMccs(getMccsValue(d));
    });
  };
  const handleCancel = () => setPreviewOpen(false);


  if (isUploading) {
    return <CenteredCircularProgress />;
  }

  const handleSave = async () => {
    setIsSaving(true);
    const foreacastOperation = getInformation(data);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/forecast/${analysis?.id}`,
        mccs,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );
      await axios.post(
        `${process.env.REACT_APP_API_URL}/forecastgeneraloperation/${analysis?.id}`,
        foreacastOperation,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "application/json",
          },
        }
      );

      //Envia email para risco
      /*const response = await axios.get(
        process.env.REACT_APP_API_NO_TOKEN_URL + "/email/risk/" + analysis?.id,
      );*/

      toast.success("Forecast preenchido com sucesso!");
      navigate("/homeregistration/");
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 400) {
          alert(
            "Erro ao realizar o envio! Por favor, verifique os dados e se está utilizando o template disponibilizado para o preenchimento do forecast e tente novamente."
          );
        } else if (error.response.status === 500) {
          alert(
            "Houve um problema no servidor. Por favor, verifique o envio e tente novamente em alguns instantes."
          );
        } else {
          alert("Erro ao enviar o Forecast. Por favor, tente novamente.");
        }
      } else {
        alert("Erro ao enviar o Forecast. Por favor, tente novamente.");
      }
    } finally {
      setIsSaving(false);
    }
  };


  return (
    <>
      <Header name={"Forecast"} token={props.token} />
      <div className="pageForecast">
        <div
          className={`forecastContainer ${
            forecastCompleted === "COMPLETO" ? "uploaded" : ""
          }`}
        >
          <div className="title">
            <h2>Forecast</h2>
            {previewOpen && (
              <p>
                Por favor, realize a <b>revisão dos dados preenchidos</b> e,{" "}
                <b>
                  estando tudo correto, clique no botão enviar localizado no
                  rodapé da página
                </b>
                . Caso encontre alguma necessidade de correção, clique em
                cancelar e repita o processo.
              </p>
            )}
            {!previewOpen && (
              <>
                <p>
                  Gostaríamos de pedir sua colaboração no preenchimento do
                  forecast, pois ele será utilizado para definir a composição da
                  garantia com a EntrePay. Por meio deste documento, saberemos
                  os segmentos de atuação dos seus clientes e as informações
                  financeiras.
                </p>
                <p>Obs: É possível realizar outro envio de Forecast, mesmo após o primeiro envio.</p>
              </>
            )}
          </div>

          <div className="infos">
            {previewOpen && <ForecastPreview data={data} mccs={mccs} />}
            {!previewOpen && (
              <>
                <CardInit
                  title={"1. Faça download do template"}
                  description={
                    "Clique aqui para realizar o download do Template do Forecast."
                  }
                  icon={<MdDownloading />}
                  onClick={() => {
                    const url = "/templateForecast.xlsx";
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = url.split("/").pop();
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  }}
                  actionName={"Baixar"}
                />

                <FileUploader
                  handleChange={readExcel}
                  name="file"
                  types={fileTypes}
                  children={
                    <CardInit
                      title={
                        "2. Preencha o template com os dados solicitados e, em seguida, faça o upload do documento aqui!"
                      }
                      description={
                        "Arraste ou clique para buscar o documento preenchido."
                      }
                      icon={<CloudDownloadOutlined />}
                      className={`cardUpload ${
                        forecastCompleted === "COMPLETO" ? "uploaded" : ""
                      }`}
                      actionName={
                        forecastCompleted === "COMPLETO"
                          ? "Forecast enviado"
                          : "Enviar"
                      }
                    />
                  }
                />
              </>
            )}
          </div>

          {previewOpen && (
            <>
              {isUploading && <CenteredCircularProgress />}
              <div className="forecastFooter">
                <div className="buttonsForecast">
                  <Button
                    sx={{
                      color: "#e5007e",
                      backgroundColor: "white",
                      border: "solid 1px",
                      borderColor: "#e5007e",
                      shadow: "none",
                      "&:hover": {
                        color: "#e5007e",
                        backgroundColor: "white",
                      },
                    }}
                    variant="contained"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "#e5007e",
                      border: "solid 1px",
                      borderColor: "#e5007e",
                      shadow: "none",
                      "&:hover": {
                        backgroundColor: "#e5007e",
                        color: "white",
                      },
                    }}
                    variant="contained"
                    onClick={handleSave}
                    disabled={isSaving}
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Forecast;
